/*
example inPlaceEditingState format: $group_$key: boolean

data: {
    payment: {
        payment_method: "paypal"
    },
    inPlaceEditingState: {
        payment_payment_method: false,
    }
}

recommended components: InputLabel, TextInput, AnchorButton

example markup:
<text-input
    rules=""
    label="Payment"
    placeholder="Payment Gateway"
    v-model="payment.payment_gateway"
    :disabled="getInputLockState('payment', 'payment_gateway')"
    />
<div @click="toggleEditingState('payment', 'payment_gateway')">
    <anchor-button
        :text="getButtonText('payment', 'payment_gateway')"
        :variant="getButtonVariant('payment', 'payment_gateway')"
        class="pl-3"
    />
</div>

 Group Only: do not pass second args [key] || key = null

 inPlaceEditingState: {
   basic: false,
   personal: false
 }

 @click = "toggleEditingState('payment')"
*/

const inPlaceEditingMixin = {
  data: function() {
    return {
      inPlaceEditingState: {},
    }
  },
  methods: {
    getEditingState(group, key = null) {
      if (key === null) {
        return this.inPlaceEditingState[group]
      }
      return this.inPlaceEditingState[`${group}_${key}`]
    },
    getInputLockState(group, key = null) {
      // lock state is the opposite of the editing state
      return !this.getEditingState(group, key)
    },
    async toggleEditingState(group, key = null, data = null) {
      console.log('what data I from api call?: ', group)
      if (group === 'cancel') {
        // console.log(this.inPlaceEditingState[group]);

        this.inPlaceEditingState[`$'app'_${key}`] = !this.inPlaceEditingState[
          `$'app'_${key}`
        ]
        this.inPlaceEditingState['app'] = !this.inPlaceEditingState['app']
        return true
      }
      if (this.getEditingState(group, key) === true) {
        await this.inPlaceEditingRequest(group, key, data)
      }
      // todo: add e debug enabling var eg: inPlaceEditingDebug -> print those + more
      // console.log(group, key);
      // console.log(this.inPlaceEditingState[group]);
      // console.log(this.getButtonText(group));
      // console.log(this.inPlaceEditingState[key]);
      // todo: attach to promise? -> notification?
      if (key === null || group === 'cancel') {
        this.inPlaceEditingState[group] = !this.inPlaceEditingState[group]
        // console.log(this.inPlaceEditingState[group]);
        return true
      }
      this.inPlaceEditingState[`${group}_${key}`] = !this.inPlaceEditingState[
        `${group}_${key}`
      ]
      return true
    },
    getButtonText(group, key = null) {
      return this.getInputLockState(group, key) ? 'Edit' : 'Save'
    },
    getButtonVariant(group, key = null) {
      return this.getInputLockState(group, key) ? 'primary' : 'success'
    },
    getModel(group, key = null) {
      if (key === null) {
        return this._data[group]
      }
      return this._data[group][key]
    },
    async inPlaceEditingRequest(group, key = null, data = null) {
      // todo: return promise?
      // setup ajax mockup here, initiate/terminate loader etc
      if (key === null) {
        await setTimeout(() => {
          console.log('inPlaceEditingRequest => @val:' + this.getModel(group))
          console.log('extraData:', data)
          console.table(this.getModel(group))
        }, 1000)
        return true
      }

      // setup ajax mockup here, initiate/terminate loader etc
      await setTimeout(() => {
        console.log(
          'inPlaceEditingRequest => @key:' +
            key +
            ' @val:' +
            this.getModel(group, key)
        )
      }, 1000)
      return true
    },
  },
}

module.exports = inPlaceEditingMixin
